* {
  padding: 0;
  margin: 0;
}

html {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.app {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: black;
}

.app > main > h1 {
  font-family: 'Gluten';
  font-size: 2.25rem;
  font-weight: 500;
  color: white;
}

.app > main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.focus {
  margin: 1.5rem 0 7rem 0;
  text-align: center;
  font-size: 1rem;
  font-family: 'Archivo Black';
  color: #B8B8B8;
}

.focus > span {
  margin: 0 0.25rem 0 0.25rem;
}

.app button.join {
  color: black;
  background: white;
  border: none;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: 650;
}

.app button:hover {
  cursor: pointer;
}

.outerModal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modal {
  position: relative;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 12px 4px;
  background-color: white;
  height: 100%;
  padding: 0.75rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
  z-index: 1000;
}

.modal > header {
  display: grid;
  grid-template-columns: 25px 1fr 25px;
  border-bottom: 1px solid black;
  padding-bottom: 0.75rem;
  margin-bottom: 1.25rem;
}

.modal > header > svg:hover {
  cursor: pointer;
}

.modal > header > h1 {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: 'Archivo Black';
}

.modal > header > * {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal > .children {
  overflow-y: scroll;
}

.modal > .children::-webkit-scrollbar {
  display: none;
}

.modal > button {
  color: white;
  background-color: black;
  padding: 0.75rem 0 0.75rem 0;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 5px;
}

.modal > button {
  cursor: pointer;
}

form.waitlist {
  width: 100%;
  display: grid;
  grid-auto-rows: auto;
  row-gap: 1.5rem;
}

form.waitlist input {
  border: 1px solid black;
  border-radius: 3px;
  margin: 0;
  padding: 0.75rem;
  height: auto;
  font-size: 1rem;
}

form.waitlist input[type='submit'] {
  margin-top: 0.5rem;
  background-color: black;
  font-family: 'Archivo Black';
  color: white;
}

form.waitlist input[type='submit']:hover {
  cursor: pointer;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.inputGroup label {
  color: rgb(116, 116, 116);
  font-size: 0.85rem;
  margin-bottom: 0.25rem;
}

.inputGroup .error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.4rem;
}

.formError {
  color: red;
}

@media (min-width: 600px) {
  .app > main > h1 {
    font-size: 4rem;
  }

  .outerModal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .modal {
    width: 100%;
    max-width: 450px;
    padding: 2rem 1.5rem 2rem 1.5rem;
    height: auto;
  }

  .modal > header {
    margin-bottom: 2rem;
  }

  form.waitlist input[type='submit'] {
    margin-top: 1rem;
  }
}